/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~@mdi/font/css/materialdesignicons.css";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "ngx-toastr/toastr";
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  scroll-behavior: smooth;
  zoom: 80%;
}
.custom-modal-backdrop {
  zoom: 125%; /* Adjust as needed */
}

/* Apply zoom to the modal backdrop */
.modal-backdrop.show {
  zoom: 125%; /* Adjust as needed */
}
.full-page-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  min-height: calc(100vh * 1.25);
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

/* Apply zoom to the modal backdrop */
